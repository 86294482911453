<template>
  <base-section
    id="nkhistory"
    space="36"
  >
    <v-container>
      <base-section-heading title="회사 연혁" />
        <v-timeline>
          <v-timeline-item
            v-for="(year, i) in items"
            :key="i"
            :color="year.color"
            small
          >
            <template v-slot:opposite>
              <span
                :class="`headline font-weight-bold ${year.color}--text`"
                v-text="year.year"
              ></span>
            </template>
            <div class="py-3">
              <h3
              :class="`font-weight-light mb-4`"
              v-text="year.text"
              >
              </h3>
            </div>
          </v-timeline-item>
        </v-timeline>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionNkhistory',

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      items: [
        {
          color: 'indigo',
          year: '2005.01',
          text: '광주교육청 학교급식관계자 살균소독제 강연',
        },
        {
          color: 'indigo',
          year: '2004.11',
          text: '보건산업진흥포럼 주제발표',
        },
        {
          color: 'indigo',
          year: '2004.07',
          text: '보건산업진흥원 HACP전문가 과정 살균소독제 강연',
        },
        {
          color: 'red',
          year: '2004.06',
          text: '식양청 주최 살균소독제 관련 워크샵 주제 발표',
        },
        {
          color: 'indigo',
          year: '2004.05',
          text: '식품첨가물 제조업 허가',
        },
        {
          color: 'red',
          year: '2000.04',
          text: '기구 등의 살균소독제 한시적 기준 1호 허가',
        },
        {
          color: 'indigo',
          year: '1997.10',
          text: 'KSA / ISO 9002 인증',
        },
        {
          color: 'indigo',
          year: '1996.12',
          text: '식품 등 수입판매업 영업 신고',
        },
        {
          color: 'indigo',
          year: '1990.01',
          text: '(주)남강 법인전환',
        },
        {
          color: 'red',
          year: '1988.05',
          text: '공중위생법 세척제 제조업 1호 등록',
        },
        {
          color: 'indigo',
          year: '1979.06',
          text: '부천공장 등록',
        },
        {
          color: 'indigo',
          year: '1976.11',
          text: '남강산업사 설립',
        },
      ],
    }),
  }
</script>
